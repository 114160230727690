import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
const Games = () => {
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const location = useLocation();
    const gameSrc = location.state ? location.state.gameSrc : null;
    const [gameRotate, setGameRotate] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const handleResize = () => {
            setIsPortrait(window.innerHeight > window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if(gameSrc){
        return (
            <div className="t1">
                <div className="page">
                    <div>
                        <div style={{
                            transform: "rotate(0deg)",  // Reset the rotation for portrait
                            transformOrigin: "top left", // Adjust the transform origin
                            position: "absolute",
                            left: 0,
                            display: "block",
                            width: "100vw",              // Swap width and height
                            height: "100vh",             // Swap width and height
                            border: "none",
                        }}>
                            <iframe
                            frameBorder="0"
                            src={gameSrc}
                            allowtransparency="true" 
                            frameborder="0" 
                            scrolling="no" 
                            class="wistia_embed" 
                            name="wistia_embed" 
                            allowfullscreen 
                            mozallowfullscreen 
                            webkitallowfullscreen 
                            oallowfullscreen 
                            msallowfullscreen 
                            width="100%" 
                            height="100%"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    }else{
        navigate("/");
    }
}

export default Games