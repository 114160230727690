import React, { useContext, useEffect, useMemo, useState } from "react";
import { Tabs } from "antd";
import "./component.css";
import InAppGameItems from "./listsItems/InAppGameItems";
import MainGameItems from "./listsItems/MainGameItems";
import { ConfigContext } from "../ConfigContext";
import SkeletonWrapper from "./common/SkeletonWrapper";
import ProductLoading from "./common/ProductLoading";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";

const { TabPane } = Tabs;

const colors = [
  "#ffcc00",
  "#ffcc33",
  "#ffcc66",
  "#ffcc99",
  "#ffcccc",
  "#ccccff",
  "#eeeeee",
  "#dddddd",
  "#cccccc",
  "#ffffff",
];
function Tablist({
  slotsTabRef,
  isFeatured=false,
  gamelist,
  setTabNum,
  tabNum,
  isImgLoading,
  selectedGame,
  handleProduct,
  StopProduct,
  startedGameList,
  setPage,
  ezSelect,
  provider,
  resetSlots,
  productLoading,
  setQuery,
  query,
  search,
  setSearch
}) {
  const [filterCategories, setFilterCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState("")
  useEffect(() => {
    if (provider !== "") {
      const title = document.querySelector(".provider-title");

      // Get each letter and apply animation with a delay and different color
      title.innerHTML = title.textContent
        .split("")
        .map((letter, index) => {
          const delay = index * 0.1; // Adjust this value for different timing
          const color = colors[index % colors.length]; // Use a color from the list
          return `<span class="letter" style="animation-delay: ${delay}s; color: ${color}">${letter}</span>`;
        })
        .join("");
    }
  }, [provider]);
  const configData = useContext(ConfigContext);
  const { t } = useTranslation();

  const categories = useMemo(() => {
    return configData
      ? Array.from({ length: 10 })
          .map((_, i) => i + 1)
          .map((cat) => ({
            key: configData[`category_tab_${cat}_type`],
            label: (
              <div
                onClick={resetSlots}
                className={
                  configData.vertical_tab
                    ? "category-game-vertical"
                    : "category-game"
                }
                style={
                  (configData.category_tab_height &&
                  configData.category_tab_width)
                    ? {
                        height: `${configData.category_tab_height}px`,
                        width: `${configData.category_tab_width}px`,
                      }
                    : {}
                }
              >
                <img
                  src={configData[`category_tab_${cat}_icon`]}
                  className={
                    configData.vertical_tab
                      ? "category-image-vertical"
                      : "category-image"
                  }
                  style={
                    (configData.category_image_height &&
                    configData.category_image_width)
                      ? {
                          height: `${configData.category_image_height}px`,
                          width: `${configData.category_image_width}px`,
                        }
                      : {}
                  }
                  alt={configData[`category_tab_${cat}_type`]}
                />
                {configData.vertical_tab || configData.category_text && (
                  <span>{t(configData[`category_tab_${cat}_type`])}</span>
                )}
              </div>
            ),
          }))
          .filter((cat, i) => configData[`category_tab_${i + 1}_active`])
      : [];
  }, [configData, resetSlots]);

  useEffect(() => {
    if (search && (search.name ==="Jili")) {
      setFilterCategories([]);
    }else if (gamelist && gamelist.length) {
      const uniqueCategories = new Set(gamelist.map(item => item.category));
      const categoriesArray = Array.from(uniqueCategories);
      setFilterCategories(categoriesArray);
    }
  }, [gamelist])
  return (
    <div
      className={`content t3 ${!isFeatured ? "content-box" : ""} ${
        configData && configData.vertical_tab ? "d-flex vertical_selected" : ""
      }`}
      id="tab-group-1"
    >
      {!isFeatured && (
        <div
          className="header-tabs"
          style={configData && configData.vertical_tab ? { border: "0", flex: 1 } : {}}
        >
          <Tabs
            tabPosition={configData && configData.vertical_tab ? "left" : "top"}
            onChange={(val) => {
              setQuery("")
              setSelectedCategory("")
              setSearch({
                show: false
              })
              setPage(0);
              setTabNum(val);
              setTimeout(() => {
                Array.from(
                  document.querySelectorAll(".ant-tabs-tab .ant-tabs-tab-btn")
                ).forEach((ele) => {
                  ele.style.background = "transparent";
                });

                Array.from(
                  document.querySelectorAll(
                    ".ant-tabs-tab-active .ant-tabs-tab-btn"
                  )
                ).forEach((ele) => {
                  ele.style.background = configData
                    ? configData.secondary_color
                    : "";
                  ele.style.color = configData ? configData.text_color : "";
                });
              }, 100);
            }}
            activeKey={tabNum}
            items={categories}
          >
            {/* {categories.map((category) => (
            <TabPane
              tab={
                <div onClick={resetSlots} className="category-game">
                  <img
                    src={category.icon}
                    className="category-image"
                    alt={category.description}
                  />
                  <span style={{ margin: "auto" }}>{category.description}</span>
                </div>
              }
              key={category.name}
            ></TabPane>
          ))} */}
          </Tabs>
        </div>
      )}
      <div
        data-bs-parent={`#tab-group`}
        className={`collapse show mt-2`}
        id={`tab`}
        // style={
        //   configData && configData.vertical_tab ? { maxHeight: "573px", width:"100%" } : {}
        // }
        style={
          configData && configData.vertical_tab ? { flex: 3 } : {}
        }
      >
      <div className="d-flex flex-col" style={
          isFeatured && gamelist && gamelist.length ? { boxShadow: `0 0 12px ${configData && configData.border_shadow_primary_color}, 0 0 12px ${configData && configData.border_shadow_primary_color} inset`, border:`2px solid ${configData ? configData.border_color : ""}`, borderRadius: "1rem", marginTop:"7px" } : {}
        }>

          {isFeatured && gamelist && gamelist.length ? <h3 className="provider-title mt-1">Hot Games</h3> : null }
          {!isFeatured ? <h3 className="provider-title mt-1">{provider}</h3> : null }
        <div className={`${isFeatured ? "d-flex flex-wrap" : "row"} me-0 ms-0 mb-0 justify-content-center`} >
          {provider !== "" && (
            <div className="underline-box">
              <div className="left"></div>
              <div className="right"></div>
            </div>
          )}
          {
            search && search.show?
            <div className="d-flex justify-content-between flex-column">
              <div className="search_container d-flex justify-content-end">
                <div className="search-box d-flex">
                  <input type="search" placeholder="Search Game" className="mr-2 px-2" style={{border: "none",outline: "none"}} value={query} onChange={e=> setQuery(e.target.value)} />
                  <div className="search_container d-flex justify-content-center align-items-center" style={{width: '30px', height: '30px', color: configData?configData.primary_color:"", background: configData?configData.secondary_color:""}}>
                    <Icon icon="material-symbols:search" style={{fontSize: '20px'}} />
                  </div>
                </div>
              </div>
              <div className="filter-categories_container d-flex flex-wrap mt-2" style={{gap: "5px"}}>
                {
                  ["All", ...filterCategories].map(filterCategory=>(
                    <p 
                      className="filter-category d-flex align-items-center justify-content-center" 
                      style={{cursor: "pointer", fontSize: "12px", padding: "0 20px", color: configData?configData.text_color:"", background: configData?configData.secondary_color:"", borderRadius: "10px"}}
                      onClick={() => setSelectedCategory(filterCategory)}
                    >
                      {filterCategory}
                    </p>
                  ))
                }
              </div>
            </div>
            :null
          }
          {productLoading && !isFeatured ? (
            <>
              <ProductLoading />
              {/* <SkeletonWrapper>
                <Skeleton height={20} style={{ marginBottom: "1rem" }} />
                <div className="grid">
                  {Array.from({ length: 12 }).map((_, index) => (
                    <div className="grid-item" key={index}>
                      <Skeleton height={105} width={105} />
                    </div>
                  ))}
                </div>
              </SkeletonWrapper> */}
            </>
          ) : (
            <>
              {ezSelect ? (
                <InAppGameItems
                  // gamelist={gamelist}
                  gamelist={(!selectedCategory || (selectedCategory == "All"))?gamelist:gamelist.filter(game=>game.category === selectedCategory)}
                  tabNum={tabNum}
                  StopProduct={StopProduct}
                  handleProduct={handleProduct}
                  startedGameList={startedGameList}
                  isImgLoading={isImgLoading}
                  selectedGame={selectedGame}
                  ezSelect={ezSelect}
                />
              ) : (
                <MainGameItems
                  vertical_tab={configData && configData.vertical_tab}
                  gamelist={gamelist}
                  isFeatured={isFeatured}
                  tabNum={tabNum}
                  StopProduct={StopProduct}
                  handleProduct={handleProduct}
                  startedGameList={startedGameList}
                  isImgLoading={isImgLoading}
                  selectedGame={selectedGame}
                  ezSelect={ezSelect}
                />
              )}
            </>
          )}
        </div>
      </div>
      </div>
    </div>
  );
}

export default Tablist;
