import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Footer from "./Footer";
import MenuNotification from "./MenuNotification";
import TransitionsModalPromotion from "./common/TransitionsModalPromotion";
import AuthService from "../service/auth.service";
import userService from "../service/user.service";
import { Modal } from "antd";
import { ConfigContext } from "../ConfigContext";
import PromotionDescription from "../components/PromotionDescription";
import DailyCheckin from "./DailyCheckin";

const ClickToClaim = () => {
  const [promoModal, setPromoModal] = useState(null);
  const [promos, setPromos] = useState([]);
  const [groups, setGroups] = useState([]);
  const configData = useContext(ConfigContext);
  const [checkinObject, setCheckinObject] = useState(null);

  useEffect(() => {
    fetchCTCs();
  }, []);

  function fetchCTCs() {
    Promise.all([
      userService.getClickToClaims(),
      userService.getPromotionGroups(),
    ])
      .then((res) => {
        if (res[0].data.length) {
          setPromos(res[0].data);
        }
        if (res[1].data.length) {
          setGroups(
            res[1].data.map((group) => ({
              ...group,
              promos: res[0].data.filter((v) => v.group == group.id),
            }))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      fetchDailyCheckins();
    }
  }, []);

  const fetchDailyCheckins = async () => {
    try {
      const { data } = await userService.getDailyCheckins();
      if (!data) {
        setCheckinObject(null);
      }
      setCheckinObject(data);
    } catch (err) {
      console.log(err);
    }
  };

  const currency = configData && configData.currency;

  const containerWidth = window.innerWidth;

  function calculateFontSize(containerWidth) {
    if (containerWidth <= 320) { // Very small phone
      return "16px";
    } else if (containerWidth <= 375) { // Small phone (e.g., iPhone SE)
      return "16px";
    } else if (containerWidth <= 414) { // Medium phone (e.g., iPhone 8 Plus)
      return "16px";
    } else if (containerWidth <= 768) { // Large phone and small tablets (e.g., iPad)
      return "16px";
    } else if (containerWidth <= 1024) { // Larger tablets (e.g., iPad Pro)
      return "16px";
    } else { // PCs and larger screens
      return "18px";
    }
  }

  return (
    <div className="t1">
      {configData &&
        configData.daily_checkin &&
        configData.checkin_appearance === "ctc" &&
        checkinObject && (
          <DailyCheckin
            checkin={checkinObject}
            fetchDailyCheckins={fetchDailyCheckins}
          />
        )}
      <div className="page click_to_claim">
        {groups.map((group) => {
          return (
            <div className="mt-2 center_wrapper">
              <div className="packet-grp" style={{ marginBottom: "10px" }}>
                <p
                  className="claim_box"
                  style={
                    group.promo_image
                      ? {
                          backgroundSize: "100% 100%",
                          backgroundImage: `url(${group.promo_image})`,
                        }
                      : {}
                  }
                ></p>
              </div>

              <div
                className="row me-0 ms-0 mb-0 justify-content-center"
                style={{ gap: "10px" }}
              >
                {group.promos.length > 0
                  ? group.promos.map((promo) => (
                      <div
                        className={`claim_box ${
                          !(
                            promo.others.applicable &&
                            (!promo.can_claim_promo_group
                              ? !promo.group_applied
                              : true)
                          )
                            ? "non_active"
                            : ""
                        }`}
                        onClick={() => {
                          setPromoModal(promo);
                        }}
                        style={{
                          // width: `${
                          //   promo.click_claim_layout === "1X1"
                          //     ? "100%"
                          //     : promo.click_claim_layout === "1X2"
                          //     ? "48%"
                          //     : promo.click_claim_layout === "1X3"
                          //     ? "30%"
                          //     : "98%"
                          // }`,
                          // aspectRatio: `${
                          //   promo.click_claim_layout === "1X1"
                          //     ? "3 / 1"
                          //     : promo.click_claim_layout === "1X2"
                          //     ? "2 / 1"
                          //     : promo.click_claim_layout === "1X3"
                          //     ? "1.5 / 1"
                          //     : "1 / 1"
                          // }`,
                          width: "30%",
                          height: "150px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          // justifyContent: "center",
                          backgroundSize: "100% 100%",
                          backgroundImage: `url(${configData.ctc_bg?configData.ctc_bg:promo.image})`,
                        }}
                      >
                        <p style={{
                          fontSize: calculateFontSize(containerWidth),
                          color: `${configData.ctc_title_color&&configData.ctc_title_color}`,
                          minHeight: "35px",
                          fontFamily: `${configData&&configData.ctc_title_font_style&&configData.ctc_title_font_style}`,
                        }} 
                        className="mb-3">
                          {promo.title} <br/> 
                        </p>
                        <p style={{
                          fontSize: calculateFontSize(containerWidth),
                          color: `${configData.ctc_reward_color&&configData.ctc_reward_color}`,
                          fontWeight: "700",
                          minHeight: "35px",
                          fontFamily: `${configData&&configData.ctc_reward_font_style&&configData.ctc_reward_font_style}`
                        }} 
                        className="mb-3">
                          {promo.bonus_amount ?
                            `${currency} ${promo.bonus_amount}`
                          : 
                            `${promo.bonus_percent} %`
                          }
                        </p>
                      </div>
                    ))
                  : null}
              </div>
            </div>
          );
        })}
        <Footer />
      </div>
      {promoModal ? (
        <TransitionsModalPromotion
          title={promoModal ? promoModal.title : "Promotion"}
          className="antd-promotion-modal"
          footer={[]}
          open={promoModal}
          onClose={() => setPromoModal(null)}
          style={{ textAlign: "center" }}
        >
          <div
            className="promo_modal"
            style={{ maxHeight: "80vh", overflowY: "auto" }}
          >
            <p className="text-md font-medium mt-2">
              {promoModal && promoModal.description ? (
                <PromotionDescription
                  field={promoModal.description}
                  data={promoModal.others}
                />
              ) : null}
            </p>
            <div>
              {promoModal.others.applicable &&
                (!promoModal.can_claim_promo_group
                  ? !promoModal.group_applied
                  : true) && (
                  <>
                    <button
                      className={`btn btn-sm bg-red-dark rounded-xs text-uppercase
                                            font-700 btn-l mt-3 me-2 h-12`}
                      onClick={() => {
                        setPromoModal(null);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        userService
                          .claimCTCPromotion(promoModal.id)
                          .then((res) => {
                            fetchCTCs();
                            // toast.success("Promotion claimed successfully!");
                            Modal.success({
                              content: <p>Promotion claimed successfully!</p>,
                            });
                            setPromoModal(null);
                          })
                          .catch((err) => {
                            toast.error(
                              `Failed to claim promotion! Please try again`
                            );
                          });
                      }}
                      className={`btn btn-sm bg-green-dark rounded-xs text-uppercase
                                            font-700 btn-l mt-3 h-12`}
                    >
                      Claim
                    </button>
                  </>
                )}
            </div>
          </div>
        </TransitionsModalPromotion>
      ) : null}
      <MenuNotification />
      {/* {isLoading && <Loading />} */}
    </div>
  );
};

export default ClickToClaim;
