import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import tokenService from '../../../service/token.service';
import authService from '../../../service/auth.service';
import { ConfigContext } from '../../../ConfigContext';

const initialValues = {
    bank: 1,
    account_number: "",
    account_name: "",
};

function SignUp3_2({ userId, setAuth, handleChange,values, setValues,validations }) {
    // const [values, setValues] = useState(initialValues);
    const [banks, setBanks] = useState([]);
    const [loading, setLoading] = useState(false)
    const configData = useContext(ConfigContext)
    const { t } = useTranslation();

    // const [validations, setValidations] = useState({
    //     bank: "",
    //     account_number: "",
    //     account_name: "",
    // })

    // const {
    //     bank: bankVal,
    //     account_name: nameVal,
    //     account_number: phoneVal,
    // } = validations

    // const navigate = useNavigate();

    const getBanks = async () => {
        const banklist = await authService.getBanklist();
        if (banklist.data) {
            const bankshow = banklist.data.filter(item => item.active);
            setBanks(bankshow);
        }
    }

    // const handleSave = async e => {
    //     e.preventDefault();
    //     setLoading(true)

    //     let _userId = userId
    //     if (!userId) {
    //         const local = localStorage.getItem("registerUser")
    //         const registeredUser = JSON.parse(local)
    //         if (!registeredUser.id) {
    //             navigate("../../signup")
    //         }
    //         _userId = registeredUser.id
    //     };

    //     try {
    //         await authService.activate({ userId: _userId, values });
    //         const uservalue = tokenService.getRegisterUser();
    //         await authService.login({ username: uservalue.username, password: uservalue.password });
    //         navigate('/');
    //         setAuth(true)
    //     } catch (err) {
    //         console.log("err => ", err);
    //         let bank_msg;
    //         let account_name_msg;
    //         let account_number_msg;
    //         if (err.response && err.response.data && err.response.data.bank) {
    //             bank_msg = err.response.data.bank;
    //         }
    //         if (err.response && err.response.data && err.response.data.account_name) {
    //             account_name_msg = err.response.data.account_name;
    //         }
    //         if (err.response && err.response.data && err.response.data.account_number) {
    //             account_number_msg = err.response.data.account_number;
    //         }
    //         if (err.response && err.response.data && err.response.data.non_field_errors) {
    //             account_number_msg = err.response.data.non_field_errors;
    //         }

    //         // setValidations({ ...validations, bank: bank_msg, account_number: account_number_msg, account_name: account_name_msg });
    //     }
    //     setLoading(false)
    // }

    useEffect(() => {
        getBanks();
    }, [])

    // const handleChange = (e) => {
    //     setValues(values => {
    //         return { ...values, [e.target.name]: e.target.value };
    //     });
    // };

    const handleBankChange = (e) => {
        const RE = /[0-9\b]$/;
        if (RE.test(e.currentTarget.value) || e.currentTarget.value === '') {
            setValues({ ...values, account_number: e.currentTarget.value });
        }
    }

    return (

        <>
            <div className="form-group mb-2">
                <label style={{ color: configData ? configData.text_color : "#fff" }}>
                    {t("Select_Bank")}
                </label>
                <select style={{ backgroundColor: configData ? configData.inputs_bg : "", color: configData ? configData.text_color : "" }} value={values.bank} onChange={handleChange} name="bank" id="deposit_bank_select" className="form-control" >
                    <option disabled selected>
                        {t("Select_Bank")}
                    </option>
                    {
                        banks.map((bank, idx) => (
                            <option value={bank.id}>
                                {bank.name}
                            </option>
                        ))
                    }
                </select>
            </div>
            <div className="form-group mb-2 standard-password-field">
                <label style={{ color: configData ? configData.text_color : "#fff" }}>
                    {t("Full_Name")}
                </label>
                <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="text" name="account_name" className="form-control" placeholder={t("Full_Name")} id="password_input" onChange={handleChange} />
                <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                {
                    validations &&
                    <span class="standard-required-message">{validations.account_name}</span>
                }
            </div>
            <div className="form-group mb-2 standard-password-field">
                <label style={{ color: configData ? configData.text_color : "#fff" }}>
                    {t("Account_Number")}
                </label>
                <input style={{ backgroundColor: configData ? configData.inputs_bg : "#000" }} type="text" name="account_number" className="form-control" placeholder={t("Account_Number")} value={values.account_number} id="password_input" onChange={handleChange} />
                <i className="glyphicon glyphicon-eye-close" id="password_input_trigger"></i>
                {
                    validations &&
                    <span class="standard-required-message">{validations.account_number}</span>
                }
            </div>

            {/* <div className="standard-button-group">
                <input style={{ backgroundColor: configData ? configData.secondary_color : "#fff" }} type="button" className="standard-button" value={t("Save_And_Continue")} onClick={handleSave} />
            </div> */}
        </>
    )
}

export default SignUp3_2