import React from "react";
import { Routes, Route } from "react-router-dom";
import ChangePwd from "./pages/ChangePwd";
import Notifications from "./pages/Notifications";
import HistoryTabs from "./components/HistoryTabs";
import Account from "./pages/Account";
import BankAccounts from "./pages/BankAccounts";
import EWallet from "./pages/EWallet";
import Deposit from "./pages/Deposit";
import EWallets from "./pages/EWallets";
import Withdraw from "./pages/Withdraw";
import Promotions from "./pages/Promotions";
import Settings from "./components/Settings";
import DepositNew from "./components/DepositNew";
import QRCodeSection from "./pages/QRCodeSection";
import Contactus from "./pages/Contactus";
import BetHistory from "./pages/BetHistory";
import Home from "./pages/Home";
import AppAccounts from "./pages/AppAccounts";
import { useState } from "react";
import authService from "./service/auth.service";
import userService from "./service/user.service";
import Header from "./components/Header";
import { useEffect } from "react";
import Downline from "./pages/Downline";
import Redirect from "./Redirect";
import { useTranslation } from "react-i18next";
import ClickToClaim from "./components/ClickToClaim";
import Profile from "./pages/Profile";
import ReferralSystem from "./components/ReferralSystem";
import VIPRanking from "./components/VIPRanking";
import Deposit2 from "./components/layout/Deposit/Deposit2";
import { ConfigContext } from "./ConfigContext";
import { useContext } from "react";
import Withdraw2 from "./components/layout/Withdraw/Withdraw2";
import BetHistory2 from "./components/BetHistory2";
import Subline from "./components/Subline";
import Games from "./pages/Games";

const MainLayout = ({ setAuth }) => {
  const [walletInfo, setWalletInfo] = useState(null);
  const [ewalletsList, setEWalletsList] = useState([]);
  const [isRefresh, setRefresh] = useState(false);
  const {t} = useTranslation();
  const configData = useContext(ConfigContext);

  const getMerchantEWalletslist = async () => {
    const ewallets = await userService.getMerchantEWallets();
    setEWalletsList(ewallets.data);
  };

  const getWalletInfo = async () => {
    setRefresh(true);
    try {
      const getWallet = await userService.getBalance();
      if (getWallet) {
        setWalletInfo(getWallet.data);
      }
    } catch (err) {
      console.log(err);
    }
    setRefresh(false);
  };

  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      getWalletInfo();
    }
  }, [localStorage.getItem("user")]);

  return (
    <div>
      <div className="t1">
        <Header walletInfo={walletInfo} setAuth={setAuth} />
      </div>
      <Routes>
        <Route path="/change-password" element={<ChangePwd />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="/history" element={<HistoryTabs />} />
        <Route path="/account" element={<Account />} />
        <Route path="/profile" element={<Profile
         getWalletInfo ={getWalletInfo}
         setAuth={setAuth} 
         walletInfo={walletInfo}
         />} />
        <Route path="/games" element={<Games />} />
        <Route
          path="/bank-accounts"
          element={
            <BankAccounts
              getWalletInfo={getWalletInfo}
              walletInfo={walletInfo}
            />
          }
        />
        <Route
          path="/ewallet/:id"
          element={
            <EWallet
              ewalletsList={ewalletsList}
              getMerchantEWalletslist={getMerchantEWalletslist}
            />
          }
        />
        <Route
          path="/online-transfer/surepay"
          element={<Deposit type={"/online-transfer/surepay"} vendor="surepay" title={t("SurePay")}/>}
        />
        <Route
          path="/online-transfer/easypay"
          element={<Deposit type={"/online-transfer/easypay"} vendor="easypay" title={t("EasyPay")}/>}
        />
        <Route
          path="/manual-transfer"
          element={<Deposit type={"/manual-transfer"} />}
        />
        <Route path="/ewallet-transfer" element={<EWallets />} />
        <Route
          path="/withdraw"
          element={configData && (configData.withdraw_style == "2")?<Withdraw2 walletInfo={walletInfo} />:<Withdraw walletInfo={walletInfo} />}
        />
        <Route path="/promotions" element={configData && configData.enable_ctc ?<ClickToClaim />:<Promotions />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/subline" element={configData && configData.sub_line ? <Subline /> : ""} />
        <Route path="/deposit" element={configData && (configData.deposit_style == "2")? <Deposit2 /> :<DepositNew />} />
        <Route path="/referral" element={<QRCodeSection />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/bet-history" element={<BetHistory />} />
        <Route path="/bet-history2" element={<BetHistory2 />} />
        <Route
          path="/"
          element={
            <Home
              getWalletInfo={getWalletInfo}
              walletInfo={walletInfo}
              isRefresh={isRefresh}
            />
          }
        />
        <Route path="/referral-system" element={<ReferralSystem />} />
        <Route path="/vip-ranking" element={<VIPRanking />} />
        <Route path="/app-accounts" element={<AppAccounts />} />
        <Route path="/downline" element={<Downline />} />
        {/* <Route path="/*" element={<Redirect />} /> */}
      </Routes>
    </div>
  );
};

export default MainLayout;
