import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import UserService from "../service/user.service";
import OnlineTransfer from '../components/OnlineTransfer'
import ManualTransfer from '../components/ManualTransfer'
import ReloadCard from '../components/ReloadCard'
import Footer from '../components/Footer'
import MenuNotification from '../components/MenuNotification'

function Deposit({ type, vendor, title }) {
    const [typelist, setTypelist] = useState([]);

    const id = useParams();
    const index = Object.values(id)[0];

    const getCouponTypes = async () => {
        const typelists = await UserService.getCouponTypelist();
        if (typelists) {
            const typeArr = typelists.data.map((item) => {
                return {
                    id: item[0],
                    name: item[1]
                }
            })
            setTypelist(typeArr);
        }
    }

    return (
        <div className='t1'>
        <div className='page'  style={{height: 'calc(100vh - 114px)', overflow: 'scroll'}}>
            <div className="page-content">
                <div className="mx-2 rounded-m">
                    <div className="mx-2 p-2 py-4">
                        {type === "/online-transfer/surepay" && <OnlineTransfer vendor={vendor} title={title}/>}
                        {type === "/online-transfer/easypay" && <OnlineTransfer vendor={vendor} title={title}/>}
                        {type === "/manual-transfer" && <ManualTransfer />}
                        {type === "/reload-card" && <ReloadCard tyid={index} typelist={typelist} getCouponTypes={getCouponTypes} />}
                    </div>
                    {/* {mode === "light-mode" && <div className={`card-overlay rounded-0 m-0 bg-black-light opacity-70`}></div>} */}
                </div>
            </div>

                <Footer />
            </div>
            <MenuNotification />
        </div>
    )
}

export default Deposit