import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ConfigContext } from '../../../ConfigContext';
import TransitionsModal from '../../common/TransitionsModal';
import QRCodeSection from '../../../pages/QRCodeSection';
import authService from '../../../service/auth.service';
import userService from '../../../service/user.service';
import LayoutCard from '../common/LayoutCard';

const Profile2 = ({ walletInfo, setAuth }) => {
    const { t } = useTranslation();
    const configData = useContext(ConfigContext)
    const [rank, setRank] = useState(null)
    const [wallet, setWallet] = useState("");
    const [currentUser, setCurrentUser] = useState(null);
    const [qrModalOpen, setQRModalOpen] = useState(false);
    const navigate = useNavigate();
    const [cuBanks, setCuBanks] = useState([]);
    const [banks, setBanks] = useState([]);


    const getCustomBanklist = async () => {
        const custombanks = await userService.getCustomBanks();
        let custombanklist = [];
        custombanks.data.forEach((game) => {
            if (game) {
                custombanklist.push({
                    bank: game.bank,
                    value: game.id,
                    label: game.name,
                    icon: game.icon,
                    number: game.number,
                    name: game.name,
                });
            }
        });
        setCuBanks(custombanklist);
    };
    const getWalletInfo = async () => {
        // setLoading(true);
        try {
            const wallet = await userService.getBalance();
            setWallet(wallet.data);
        } catch (err) {
            console.log(err);
        }
    };
    const getBankList = async () => {
        const getBanks = await userService.getBanks();
        setBanks(getBanks.data);
    }
    useEffect(() => {
        const fetchWalletInfo = async () => {
            const user = authService.getCurrentUser();
            if (user) {
                await getWalletInfo();
                setCurrentUser(user);
            }
        };
        getBankList();
        fetchWalletInfo();
        getCustomBanklist();
    }, [localStorage.getItem("user")]);


    useEffect(() => {
        if (walletInfo && walletInfo.rank) {
            const userRank = walletInfo.rank.split(" ")
            if (userRank[1]) {
                setRank(userRank[1])
            }
        }
    }, [walletInfo]);



    return (
        <div className='profile-page2 layout2'>
            <div className="tab-menu-background-container" style={{ backgroundColor: configData ? configData.layout_card_bg : "#242424", }}>
                <div className="tab-menu-container d-flex" data-style="vertical" style={{ backgroundColor: configData ? configData.primary_color : "" }}>
                    <a href="#" data-active="true" onClick={(e) => {
                        navigate("/profile")
                    }}>
                        <img src={configData ? configData.side_menu_icon_11 : ""} alt="" style={{filter: "brightness(0) invert(1)"}} />
                        {t("Profile")}
                    </a>
                    <a href="#" data-active="false" onClick={(e) => {
                        navigate("/change-password")
                    }}>
                        <img src={configData ? configData.side_menu_icon_9 : ""} alt="" style={{filter: "brightness(0) invert(1)"}} />
                        {t("Change_Password")}
                    </a>
                </div>
            </div>
            <div className="standard-form-container" style={{}}>
                <div className="acc_container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="standard-form-title" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "" }}>
                                {t("Account_Information")}
                            </div>
                            <div className="standard-content-info">
                                <LayoutCard>
                                    <div className="standard-content-block" style={{}}>
                                        <table className="table profile-summary-table" style={{ color: configData ? configData.text_color : "" }}>
                                            <tbody>
                                                <tr>
                                                    <th style={{ color: configData ? configData.text_color : "" }}>{t("Username")}</th>
                                                    <td>:</td>
                                                    <td>{walletInfo && walletInfo.username
                                                        ? walletInfo.username
                                                        : t("No_Account")}</td>
                                                </tr>
                                                <tr>
                                                    <th style={{ color: configData ? configData.text_color : "" }}>{t("Rank")}</th>
                                                    <td>:</td>
                                                    <td class="col-12 text-nowrap d-flex align-items-center">
                                                        <p style={{ fontWeight: "bold", color: configData ? configData.text_color_secondary : "" }}>
                                                            {rank?rank:"NA"}
                                                        </p>
                                                        {rank ?
                                                            <img src={require("../../../images/trophy-icon.png")} alt="" width="15px" height="15px" />
                                                            : null
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th style={{ color: configData ? configData.text_color : "" }}>{t("Balance")}</th>
                                                    <td>:</td>
                                                    <td>&nbsp;RM&nbsp;
                                                        {currentUser && walletInfo ? walletInfo.balance : "0.00"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </LayoutCard>
                                <LayoutCard>
                                    <div className="standard-content-block">
                                        <div className="banking-details-header d-flex justify-content-between align-items-center">
                                            <label style={{ color: configData ? configData.text_color : "", textTransform: "uppercase" }}>{t("Bank_Accounts")}</label>
                                            {/* <a href="" style={{backgroundColor: configData?configData.primary_color:""}}>
                                                <img loading="lazy" style={{width:"auto", height:"20px"}} src="//nx-cdn.nexus2wl.com/Images/nexus-alpha/dark-orange/mobile/tabs/edit.svg?v=20231011-1" />
                                            </a> */}
                                        </div>
                                        <div id="bank_account_carousel" className="bank-info-container d-flex flex-col" data-interval="false" data-ride="carousel">

                                            {
                                                cuBanks.map((cuBank, index) => {
                                                    const newBank = banks.filter(bank => bank.id == cuBank.bank)
                                                    let bankName = "";
                                                    if (newBank && newBank.length) {
                                                        bankName = newBank[0].name
                                                    }
                                                    return (
                                                        <div className="carousel-inner mb-2">
                                                            <div className="bank-info-block item active" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.tertiary_color : "" }}>
                                                                <div className="account-name d-flex justify-content-between align-items-center" style={{ minHeight: "25px" }}>
                                                                    {cuBank.name}
                                                                    <img className="bank-icon" style={{ width: "auto", height: "20px" }} loading="lazy" onerror="this.style.display='none'" src={cuBank.icon} />
                                                                </div>
                                                                <div className="account-number">
                                                                    {cuBank.number}
                                                                </div>
                                                                <hr />
                                                                <div className="bank-name">
                                                                    {bankName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            <ol className="carousel-indicators">
                                                <li data-target="#bank_account_carousel" data-slide-to="0" className="active"></li>
                                            </ol>
                                        </div>
                                    </div>
                                </LayoutCard>
                            </div>
                            <div className="standard-form-title mt-5" style={{ color: configData ? configData.text_color : "", backgroundColor: configData ? configData.secondary_color : "" }}>
                                {t("Referral")}
                            </div>

                            <div>
                                <section className="referral-field">
                                    <label style={{ color: configData ? configData.text_color : "" }}>{t("Referral_Code")}:</label>
                                    <a href="#" onClick={(e) => {
                                        e.preventDefault()
                                        setQRModalOpen(true);
                                    }}>
                                        <div id="ReferralCode" className="referral-code-container mx-auto text-center" style={{ color: configData ? configData.text_color_secondary : "", border: `2px dashed ${configData ? configData.border_color : ""}`, backgroundColor: configData ? configData.primary_color : "" }}>{wallet.referral_link}</div>
                                    </a>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <TransitionsModal
                    title="QR Code"
                    open={qrModalOpen}
                    onClose={() => setQRModalOpen(false)}
                >
                    <QRCodeSection />
                </TransitionsModal>
            </div>
        </div>
    )
}

export default Profile2